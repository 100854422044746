.hero-section {
    position: relative;
    height: 610px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    color: #ffffff;
    margin-bottom: 60px;
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: 
        linear-gradient(0deg, rgba(68, 119, 206, 0.60) 0%, rgba(68, 119, 206, 0.60) 100%), 
        url('../../assets/hero image.jpg') center / cover no-repeat; 
    z-index: -1;
}

.content {
    margin-left: 46px;
    width: 60%; 
}

.content h1 {
    color: rgba(255, 255, 255, 0.96);
    font-family: "Poppins", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.3;
    width: 100%; 
    word-wrap: break-word;
    margin: 0;
    margin-right: 100px;
}

.content h1 span {
    color: rgba(236, 188, 85, 0.96);
}

.mockup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 60px; 
    width: auto;
    height: 580px; 
}

.mockup img {
    height: 100%;
    width: auto;
    object-fit: contain;
    padding: 0;
}


@media (max-width: 1150px) {
    .hero-section{
        height: 600px;
    }
    .bg-image {
        height: 600px; 
    }

    .content h1 {
        font-size: 37px; 
    }
    .mockup{
        height: 480px;
        width: auto;
    }
}

@media (max-width: 1030px) {
    

    .content h1 {
        font-size: 32px; 
    }
    .mockup {
        right: 30px; 
        height: 400px;
        width: auto;
    }
}



@media (max-width: 888px) {
    .hero-section{
        height: 550px;
    }
    .bg-image {
        width: 100%;
    height: 100%;
    }
     
    .content {
        margin-left: 28px;
    }
    .content h1 {
        font-size: 28px; 
    }

    .mockup {
        right: 25px;
        height: 360px; 
        width: auto;
    }
}

@media (max-width: 780px) {
    
    .content {
        margin-left: 24px;
    }
    .content h1 {
        font-size: 23px; 
        width: 400px;
    }

    .mockup {
        right: 25px;
        height: 320px; 
        width: auto;
    }
}

@media (max-width: 630px) {
    .hero-section{
        height: 510px;
    }
    .bg-image {
        width: 100%;
    height: 100%;
    }
     
    .content {
        margin-left: 18px;
    }
    .content h1 {
        font-size: 20px; 
        width: 330px;
    }

    .mockup {
        right: 15px;
        width: 120px;
        height: 196px;
    }
}

@media (max-width: 520px) {
    .hero-section{
        height: 597px;
    }
    .bg-image {
        width: 100%;
    height: 100%;
    }
     
    .content {
        width: 100%;
        margin-top: -400px;
       margin-left: 25px;
       margin-right: 0px;
    }
    .content h1 {
        font-size: 21px; 
        width: 100%;
        margin-left: -17px;
        text-align: center;
    }

    .mockup {
        display: flex; /* Enables flexbox */
        align-items: center; /* Centers the image vertically */
        justify-content: center; /* Centers the image horizontally */
        position: absolute; /* Keeps the container in its specified position */
        margin: 0 auto; /* Centers the container itself horizontally */
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        top: 170px; /* Adjusts the vertical position (replaces margin-top) */
        height: 380px; /* Fixed height for the container */
        width: 100%; /* Ensures the container spans the full width */
        text-align: center;
    }

    .mockup img {
        max-height: 100%; /* Ensures the image fits the container's height */
        max-width: 100%; /* Ensures the image fits the container's width */
        object-fit: contain; /* Maintains aspect ratio without cropping */
        margin: auto; /* Centers the image within its container */
        display: block; /* Prevents inline spacing issues */
       padding-left: 40px;
    }
}

@media (max-width: 360px) {
    .hero-section{
        height: 597px;
    }
    .bg-image {
        width: 100%;
    height: 100%;
    }
     
    .content {
        width: 100%;
        margin-top: -400px;
    
        
    }
    .content h1 {
        font-size: 19px; 
        width: 100%;
        margin-left: -10px;
    }

    .mockup {
        display: flex; /* Enables flexbox */
        align-items: center; /* Centers the image vertically */
        justify-content: center; /* Centers the image horizontally */
        position: absolute; /* Keeps the container in its specified position */
        margin: 0 auto; /* Centers the container itself horizontally */
        padding: 0px;
       
        top: 170px; /* Adjusts the vertical position (replaces margin-top) */
        height: 380px; /* Fixed height for the container */
        width: 100%; /* Ensures the container spans the full width */
        text-align: center;
    }

    .mockup img {
        max-height: 100%; /* Ensures the image fits the container's height */
        max-width: 100%; /* Ensures the image fits the container's width */
        object-fit: contain; /* Maintains aspect ratio without cropping */
        margin-left: 91px;
        display: block; /* Prevents inline spacing issues */
        padding: 0; 
    }
}