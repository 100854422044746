.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #000;
    color: #fff;
    padding: 16px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  
  .footer-left {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 10px;
    margin-right: 20px; 
  }
  
  .footer-logo {
    width: 100px;
    cursor: pointer;
    height: 100px;
  }
  
  .footer-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex-grow: 1; 
    margin-top: 24px;
  }
  
  .footer-title {
    color: #ECBC55;
    font-family: "Josefin Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }
  
  .footer-subtitle {
    color: #FFF4CC;
    font-family: "Public Sans";
    font-size: 25px;
    font-style: normal;
    margin-top: -3px;
    margin-bottom: 14px;
    font-weight: 800;
    line-height: normal;
    
  }
  
  .footer-address {
    color: #FFF;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6;

  }
  
  .footer-privacy {
    color: #FFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor: pointer;
margin-bottom: 12px;
  }

  
  .footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 54px;
    margin-right: 46px;
  }
  
  .footer-contact-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .footer-contact-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 259px;
    height: 46px;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 46px;
    border-radius: 10px;
    background: #ecbc55;
    margin-bottom: 30px;
    
  }

  .footer-contact{
    margin-bottom: 24px;
  }
  
  .footer-contact-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 17px;
    font-weight: 700;
    color: #432c51;
  }
  
  .footer-contact-item2 {
    margin-left: -38px;
  }
  
  .footer-contact-item .material-icons {
    margin-right: 18px;
    text-decoration: none;
    font-size: 20px;
    color: #432c51;
  }
  
  .footer-contact-item2 .material-icons {
    margin-right: 4px;
  }
  
  .footer-copyright {
    color: #FFF;
    margin-right: -38px;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
@media (max-width: 1150px) {
    .footer {
      padding: 14px;
      font-size: 12px;
    }
  
    .footer-left {
      margin-left: 12px;
      margin-right: 16px;
    }
  
    .footer-logo {
      width: 80px;
      height: 80px;
    }
  
    .footer-title {
      font-size: 30px;
    }
  
    .footer-subtitle {
      font-size: 18px;
      margin-top: -5px;
      margin-bottom: 10px;
    }
  
    .footer-address {
      font-size: 16px;
      margin-bottom: 25px;
    }
  
    .footer-privacy {
      font-size: 16px;
      margin-bottom: 10px;
    }
  
    .footer-right {
      margin-top: 44px;
      margin-right: 30px;
    }
  
    .footer-contact-section {
      margin-bottom: 8px;
    }
  
    .footer-contact-box {
      width: 220px;
      height: 40px;
      padding-left: 40px;
      font-size: 15px;
    }
  
    .footer-contact-item {
      font-size: 15px;
    }
  
    .footer-copyright {
      font-size: 15px;
      margin-right: -30px;
    }
  }
  
  
  @media (max-width: 900px) {
    .footer {
      padding: 12px;
      font-size: 11px;
    }
  
    .footer-left {
      margin-left: 10px;
      margin-right: 12px;
    }
  
    .footer-logo {
      width: 70px;
      height: 70px;
    }
  
    .footer-title {
      font-size: 24px;
    }
  
    .footer-subtitle {
      font-size: 15px;
      margin-top: -5px;
      margin-bottom: 8px;
    }
  
    .footer-address {
      font-size: 13px;
    }
  
    .footer-privacy {
      font-size: 13px;
      margin-top: 22px;
    }
  
    .footer-right {
      margin-top: 34px;
      margin-right: 24px;

    }
  
    .footer-contact-section {
      margin-bottom: 6px;
    }
  
    .footer-contact-box {
      width: 200px;
      height: 36px;
      padding-left: 30px;
      font-size: 13px;
    }
  
    .footer-contact-item {
      font-size: 13px;
    }
    .footer-contact-item2 {
        margin-left: -24px;
      }
      
  
    .footer-copyright {
      font-size: 12px;
     margin-right: -14px;
    }
  }
  
  
  @media (max-width: 745px) {
    .footer {
      padding: 10px;
      font-size: 10px;
    }
  
    .footer-left {
      margin-left: 8px;
      margin-right: 10px;
    }
  
    .footer-logo {
      width: 60px;
      height: 60px;
    }
  
    .footer-title {
      font-size: 20px;
    }
  
    .footer-subtitle {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 6px;
    }
  
    .footer-address {
      font-size: 11px;
    }
  
    .footer-privacy {
      font-size: 11px;
      margin-top: 14px;
    }
  
    .footer-right {
      margin-top: 24px;
      margin-right: 16px;
    }
  
    .footer-contact-section {
      margin-bottom: 4px;
    }
  
    .footer-contact-box {
      width: 180px;
      height: 32px;
      padding-left: 20px;
      font-size: 11px;
    }
  
    .footer-contact-item {
      font-size: 11px;
    }
    .footer-contact-item2 {
        margin-left: -12px;
      }
      
  
    .footer-copyright {
      font-size: 10.2px;
      margin-right: -20px;
    }
  }
  @media (max-width: 745px) {
    .footer {
      padding: 10px;
      font-size: 10px;
    }
  
    .footer-left {
      margin-left: 8px;
      margin-right: 10px;
    }
  
    .footer-logo {
      width: 60px;
      height: 60px;
    }
  
    .footer-title {
      font-size: 20px;
    }
  
    .footer-subtitle {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 6px;
    }
  
    .footer-address {
      font-size: 11px;
    }
  
    .footer-privacy {
      font-size: 11px;
      margin-top: 14px;
    }
  
    .footer-right {
      margin-top: 24px;
      margin-right: 16px;
    }
  
    .footer-contact-section {
      margin-bottom: 4px;
    }
  
    .footer-contact-box {
      width: 180px;
      height: 32px;
      padding-left: 20px;
      font-size: 11px;
    }
  
    .footer-contact-item {
      font-size: 11px;
    }
    .footer-contact-item2 {
        margin-left: -12px;
      }
      
  
      .footer-copyright {
        font-size: 11px;
        white-space: normal;
        margin-right: -10px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        text-align: center; /* Center the text if required */
      }
      
      .footer-copyright .line1 {
        display: block; /* Ensure each span starts on a new line */
        margin-bottom: 2px; /* Optional spacing between lines */
      }
      
      .footer-copyright .line2 {
        display: block; /* Ensure this spans a new line */
      }
  }
  
  
  @media (max-width: 580px) {
    .footer {
      padding: 8px;
      font-size: 9px;
    }
  
    .footer-left {
      margin-left: 6px;
      margin-right: 8px;
    }
  
    .footer-logo {
      width: 50px;
      height: 50px;
    }
  
    .footer-title {
      font-size: 16px;
    }
  
    .footer-subtitle {
      font-size: 10px;
      margin-top: 0px;
      margin-bottom: 4px;
    }
  
    .footer-address {
      font-size: 9px;
    }
  
    .footer-privacy {
      font-size: 9px;
      margin-bottom: 4px;
    }
  
    .footer-right {
      margin-top: 23px;
      margin-right: 10px;
    }
  
    .footer-contact-section {
      margin-bottom: 2px;
    }
  
    .footer-contact-box {
      width: 160px;
      height: 28px;
      padding-left: 15px;
      font-size: 9px;
    }
  
    .footer-contact-item {
      font-size: 9px;
    }
  
    .footer-copyright {
      font-size: 9px;
      margin-top: -2px;
      margin-right: -5px;
    }
  }

  @media (max-width: 488px) {
    .footer {
      padding: 8px;
      font-size: 9px;
    }
  
    .footer-left {
      margin-left: 6px;
      margin-right: 8px;
    }
  
    .footer-logo {
      width: 40px;
      height: 40px;
    }
  
    .footer-title {
      font-size: 13px;
    }
  
    .footer-subtitle {
      font-size: 9px;
      margin-top: 0px;
      margin-bottom: 4px;
    }
  
    .footer-address {
      font-size: 8px;
      margin-bottom: 38px;
    }
  
    .footer-privacy {
      font-size: 6px;
      margin-top: 8px;
    }
  
    .footer-right {
      margin-top: 24px;
      margin-right: 10px;
    
    }
  
    .footer-contact-section {
      margin-bottom: 2px;
    }
  
    .footer-contact-box {
      width: 140px;
      height: 28px;
      padding-left: 15px;
      font-size: 8px;
    }
  
    .footer-contact-item {
      font-size: 8px;
    }
  
    .footer-copyright {
      font-size: 6px;
      margin-top: -2px;
      margin-right: -5px;
    }
  }

  @media (max-width: 365px) {
    .footer {
      padding: 8px;
      font-size: 9px;
    }
  
    .footer-left {
      margin-left: 6px;
      margin-right: 8px;
    }
  
    .footer-logo {
      width: 38px;
      height: 38px;
    }
  
    .footer-title {
      font-size: 12px;
    }
  
    .footer-subtitle {
      font-size: 8.6px;
      margin-top: 0px;
      margin-bottom: 4px;
    }
  
    .footer-address {
      font-size: 7px;
      margin-bottom: 38px;
    }
  
    .footer-privacy {
      font-size: 6px;
      margin-top: 0px;
    }
  
    .footer-right {
      margin-top: 20px;
      margin-right: 10px;
    }
  
    .footer-contact-section {
      margin-bottom: 0px;
      gap: 0px;
    }
  
    .footer-contact-box {
      width: 130px;
      height: 24px;
      padding-left: 15px;
      font-size: 7px;
    }
  
    .footer-contact-item {
      font-size: 8px;
    }
  
    .footer-copyright {
      font-size: 6px;
      margin-right: -9px;
    }
  }
  