.privacy-bg {
    background-color: #Fff;
    padding-top: 20px;
    padding-left: 38px;
    padding-right: 38px;
    padding-bottom: 80px;
}


.privacy-policy-heading {
    color: #000;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 0px;
}

.privacy-container {
    background-color: white;
    border: 1px solid white;
    border-radius: 35px;
    background: #FFF;
    box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.25);
    padding-top: 50px;
    padding-left: 90px;
    padding-right: 86px;
    padding-bottom: 27px;
}


.main-para {
    color: #1E1E1E;
font-family: "Public Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22px;
}



.sub-heading-privacy-policy {
    color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 26px;
}

.privacy-policy-para {
    color: #1E1E1E;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

@media (max-width: 1124px) {
    
    .privacy-policy-heading{
        font-size: 38px;
    }
    .privacy-container{
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 44px;
        padding-bottom: 47px;
    }

    .main-para{
        font-size: 12px;
        line-height: 2;
    }
    .sub-heading-privacy-policy{
        font-size: 15px;
        line-height: 2;
    }
    .privacy-policy-para{
        font-size: 12px;
        line-height: 2;
    }
}

@media (max-width: 900px) {
    
    .privacy-container{
        padding-left: 31px;
        padding-right: 29px;
        padding-top: 38px;
        padding-bottom: 42px;
    }
    .privacy-policy-heading{
        font-size: 34px;
    }
   
    .main-para{
        font-size: 10px;
        line-height: 1.8;
    }
    .sub-heading-privacy-policy{
        font-size: 13px;
        line-height: 1.8;
    }
    .privacy-policy-para{
        font-size: 10px;
        line-height: 1.8;
    }
}


@media (max-width: 640px) {
    .privacy-container{
        padding-left: 26px;
        padding-right: 23px;
        padding-top: 33px;
        padding-bottom: 37px;
    }
    
    .privacy-policy-heading{
        font-size: 20px;
    }
   
    .main-para{
        font-size: 9px;
        line-height: 1.6;
    }
    .sub-heading-privacy-policy{
        font-size: 11px;
        line-height: 1.6;
    }
    .privacy-policy-para{
        font-size: 9px;
        line-height: 1.6;
    }
}

@media (max-width: 440px) {
    .privacy-container{
        padding-left: 16px;
        padding-right: 13px;
        padding-top: 23px;
        padding-bottom: 47px;
    }
    
    .privacy-policy-heading{
        font-size: 20px;
    }
   
    .main-para{
        font-size: 7px;
        line-height: 1.4;
    }
    .sub-heading-privacy-policy{
        font-size: 10px;
        line-height: 1.4;
    }
    .privacy-policy-para{
        font-size: 7px;
        line-height: 1.4;
    }
}