.why {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -50px;
    
    
}

.why-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.why-title {
    color: #432C51;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.why-boxes {
    display: flex;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 40px;
    width: 100%;
    gap: 70px;
    align-items: center; 
    flex-wrap: wrap; 
}

.why-box {
    width: 347px;
    height: 321px;
    background-color: #fff;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.why-box img {
    width: 320px;  
    height: 168px; 
    padding: 10px;
    margin-bottom: 0px;
    object-fit: cover;
}

.why-img {
    object-position: 10px -30px;
}
.why-box .why-sp1{
    width: 180px;
    object-position: 5px -20px;
}
.why-text {
    text-align: center;
}

.why-text h3 {
    margin: 0px 0;
    margin-top: 4px;
    margin-bottom: 10px;
    color: #000;
    text-align: center;
    font-feature-settings: 'dlig' on;
    font-family: "Public Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.why-text p {
    color: #432C51;
    text-align: center;
    font-feature-settings: 'dlig' on;
    font-family: "Public Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin-left: 28px;
    margin-right: 28px;
    margin-bottom: 80px;
}

/* Media Query for 1150px */
@media (max-width: 1150px) {
    .why {
        margin-top: -60px;
    }

    .why-boxes {
        gap: 50px; /* Reduced gap */
        justify-content: center;
        align-items: center;
    }
    .why-title{
        font-size: 38px;
    }

    .why-box {
        width: 270px;
        height: 290px;
    }

    .why-box img {
        width: 260px;
        height: 150px;
    }
    .why-box .why-sp1{
        width: 120px;
        object-position: 5px -20px;
    }

    .why-text h3{
        font-size: 18px;
    }

    .why-text p {
        font-size: 16px;
        margin-left: 18px;
        margin-right: 18px;
    }
}

/* Media Query for 990px */
@media (max-width: 1005px) {
    .why {
        margin-top: -60px;
    }
    .why-title{
        font-size: 34px;
    }

    .why-boxes {
        gap: 50px; 
        justify-content: center;
        align-items: center;
    }

    .why-box {
        width: 220px;
        height: 260px;
    }

    .why-box img {
        width: 210px;
        height: 130px;
    }

    .why-text h3{
        font-size: 16px;
        line-height: normal;
    }
    .why-box .why-sp1{
        width: 120px;
        object-position: 5px -20px;
    }

    .why-text p {
        font-size: 14px;
        margin-left: 15px;
        margin-right: 15px;
        line-height: normal;
    }
}


@media (max-width: 880px) {
    .why {
        margin-top: -60px;
    }

    .why-title{
        font-size: 30px;
    }

    .why-boxes {
        gap: 50px; /* Further reduced gap */
        justify-content: center;
        align-items: center;
    }

    .why-box {
        width: 200px;
        height: 240px;
    }

    .why-box img {
        width: 190px;
        height: 110px;
    }
    .why-box .why-sp1{
        width: 120px;
        object-position: 5px -20px;
    }

    .why-text h3{
        font-size: 14px;
        line-height: normal;
    }

    .why-text p {
        font-size: 12px;
        line-height: normal;
        margin-left: 10px;
        margin-right: 10px;
    }
}



/* Media Query for 750px */
@media (max-width: 800px) {
    .why {
        margin-top: -60px;
    }

    .why-title{
        font-size: 25px;
    }

    .why-boxes {
        gap: 50px; 
        justify-content: center;
        align-items: center;
    }

    .why-box {
        width: 160px;
        height: 224px;
    }

    .why-box img {
        width: 150px;
        height: 100px;
    }
    .why-box .why-sp1{
        width: 100px;
        object-position: 0px -13px;
    }


    .why-text h3{
        font-size: 13px;
    }

    .why-text p {
        font-size: 11px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (max-width: 710px) {
    .why {
        margin-top: -60px;
    }
    .why-title{
        font-size: 20px;
        margin-bottom: 28px;
    }
    .why-boxes {
        gap: 40px; 
        justify-content: center;
        align-items: center;
    }

    .why-box {
        width: 150px; 
        height: 190px;
    }

    .why-box img {
        width: 140px; 
        height: 90px;
    }

    .why-text h3 {
        font-size: 12px; 
    }

    .why-text p {
        font-size: 10px; 
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (max-width: 630px) {
    .why {
        margin-top: -60px;
    }
    .why-title{
        font-size: 20px;
        margin-bottom: 28px;
    }
    .why-boxes {
        gap: 30px; 
        justify-content: center;
        align-items: center;
    }

    .why-box {
        width: 120px; 
        height: 160px;
    }
    

    .why-box img {
        width: 110px;
        height: 70px;
    }

    .why-box .why-sp1{
        width: 80px;
        object-position: 0px -13px;
    }

    .why-text h3 {
        font-size: 10px; 
    }

    .why-text p {
        font-size: 8px; 
        margin-left: 10px;
        margin-right: 10px;
    }
}



@media (max-width: 520px) {
    .why {
        margin-top: -30px;
    }
    .why-title{
        font-size: 24px;
        margin-bottom: 38px;
    }
    .why-boxes {
        gap: 46px; 
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .why-box {
        width: 320px; 
        height: 360px;
        box-sizing: border-box;
    }
    .why-box .why-sp2{
        object-position: 0px -10px;
    }
    .why-box .why-sp1{
        width: 280px;
        object-position: -10px -40px;
    }


    .why-box img {
        width: 280px; 
        height: 240px;
    }

    .why-text h3 {
        font-size: 18px; 
        margin-bottom: 0px;
    }

    .why-text p {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

