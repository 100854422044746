@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.clients-section {
  text-align: center;
  padding: 0px;
  margin-top: 0px;
  background-color: #fff;
  overflow: hidden;
}

.clients-title {
  color: #432C51;
  margin-top: 0px;
  margin-bottom: 60px;
  font-family: Inter, sans-serif;
  font-size: 40px;
  /* Default for large screens */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.client-slider {
  width: 100%;
  background: #F8F8F8;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.25) inset;
}

.clients-slider {
  width: 99%;
  margin: 0px auto;
}

.client {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  position: relative;

  overflow: visible;
 
}

.client-logo {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.client-img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  object-fit: contain;
  transition: transform 0.3s ease;
  overflow: visible;
}



.client-name {
  color: #432C51;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-right: 40px;
  white-space: nowrap;
}


@media (max-width: 1024px) {
  .clients-title {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .client-img {
    width: 80px;
  
    height: 80px;

    margin-left: 20px;
    margin-right: 30px;


  }

  .client-name {
    font-size: 20px;
    margin-right: 30px;
  }
}

@media (max-width: 964px) {
  .client-img {
    margin-left: 20px;
    margin-right: 30px;
  }
}


@media (max-width: 767px) {
  .clients-title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .client-img {
    width: 70px;
    height: 70px;
  }

  .client-name {
    font-size: 18px;
    margin-right: 20px;
  }
}

@media (max-width: 555px) {

  .clients-title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .client-img {
    width: 70px;
    height: 70px;
   
    padding-left: 40px;
  }

  .client-name {
    font-size: 16px;
    margin-right: 10px;
  
  }
}

@media (max-width: 480px) {
  .clients-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .client-img {
    width: 60px;

    height: 60px;
    margin-left: 40px;
    margin-right: 40px;

  }

  .client-name {
    font-size: 16px;

    margin-right: 10px;
  }
}