/* Default Styles */
.about-container {
    margin: 25px;
    border-radius: 50px;
    background: rgba(68, 119, 206, 0.15);
    margin-bottom: 60px;
    padding: 20px;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .about-header {
    text-align: center;
    margin-left: 130px;
    margin-bottom: 36px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #35155D;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .about-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  
  .about-left {
    flex: 0 0 37%;
    margin-left: 30px;
    margin-top: 0px;
    box-sizing: border-box;
  }
  
  .about-left h3 {
    color: #432C51;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }
  
  .about-left p {
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }
  
  .about-middle {
    flex: 0 0 10%;

    margin-left: -870px;
    margin-top: -120px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about-para{
    color: #35155D;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 27px;
text-align: center;
margin: 0px;
margin-bottom: 20px;
margin-left: 130px;
  }
  .middle-image {
    width: 350px;
    height: auto;

    border-radius: 10px;
  }
  
  .about-right {
    flex: 0 0 30%;
    margin-left: -100px;
    margin-top: -240px;
    margin-right: 20px;
    text-align: center;
  }
  
  .about-right h3 {
    color: #35155D;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
   margin-top: -40px;
    margin-bottom: 20px;
  }
  
  .app-image {
    width: 232.521px;
    height: 69px;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 10px;
    background: url('../../assets/google play.png') lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 10px 0px #FFF;
    margin: 0 auto;
  }
  
  .about-visit{
    margin-top: 60px;
    display: none;
  }

  .about-visitUs{
    color: #35155D;
text-align: center;
font-family: Inter;
display: none;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 0px;
  }
  .about-address{
    color: #000;
    margin-top: -18px;
    display: none;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;

  }
  /* Media Queries */
  
  /* For screens 1250px and below */
  @media (max-width: 1250px) {
    .about-container {
      height: 390px;
    }
    .about-left {
        flex: 0 0 35%;
        margin-left: 24px;
        box-sizing: border-box;
      }
  
    .about-header {
      font-size: 36px;
      margin-left: 100px;
    }
    .about-para{
      font-size: 18px;
      margin-left: 80px;
    }

    .about-middle{
      margin-top: -120px;
    }
    .middle-image {
    
      margin-left: 160px;
      width: 320px;
    }
  
    .about-right {
      margin-left: -80px;
      margin-top: -230px;
    }
    .about-right h3{
        font-size: 22px;
    }
  }

  @media (max-width: 1084px) {
    .about-header {
      font-size: 34px;
      margin-left: 80px;
    }
    .about-para{
      font-size: 16px;
      margin-left: 80px;
    }
    .about-left {
      flex: 0 0 30%;
      margin-left: 18px;
    }

    .about-left h3{
        font-size: 23px;
    }

    .about-left p{
        font-size: 14px;
    }
  
    .about-middle {
      margin-left: -850px;
      margin-right: 0px;
    }
  
    .middle-image {
      width: 300px;
    }
  
    .about-right {  
      flex: 0 0 25%; 
      margin-right: 0px;
      margin-top: -220px;
    }
    .about-right h3{
       font-size: 18px;
       margin-left: 0px;
    }
  
    .app-image {
      width: 200px;
      margin-left: 26px;
      height: 60px;
    }
  }
  
  /* For screens 900px and below */
  @media (max-width: 950px) {

    .about-container{
        height: 360px;
    }
    .about-header {
        font-size: 30px;
        margin-left: 65px;
        margin-bottom: 0px;
      }
      .about-para{
        font-size: 15px;
        margin-left: 65px;
      }
    
      .about-left {
        flex: 0 0 30%;
        margin-left: 14px;
      }
  
      .about-left h3{
          font-size: 20px;
      }
  
      .about-left p{
          font-size: 12px;
      }
    
      .about-middle {
        margin-left: -750px;
        margin-right: 0px;
        margin-top: -60px;
      }
    
      .middle-image {
        width: 278px;
      }
    
      .about-right {  
        flex: 0 0 20%; 
        
      }
      .about-right h3{
         font-size: 16px;
         margin-top: 26px;
         margin-left: 20px;
      }
  
      .about-visitUs{
        font-size: 14px;
      }
      .about-address{
        font-size: 14px;
      }
    
      .app-image {
        width: 180px;
        margin-left: 40px;
        height: 54px;
      }
  }
  
  /* For screens 700px and below */
  @media (max-width: 860px) {
   
    .about-container{
        height: 340px;
    }
    .about-header {
        font-size: 26px;
        margin-left: 52px;
        margin-bottom: 0px;
      }
    
      .about-left {
        flex: 0 0 28%;
        margin-left: 0px;
      }
  
      .about-left h3{
          font-size: 16px;
      }
  
      .about-left p{
          font-size: 11px;
      }
    
      .about-middle {
        margin-top: -100px;
        margin-left: -740px;
        margin-right: 0px;
      }
    
      .middle-image {
        width: 264px;
      }
    
      .about-right {  
        flex: 0 0 20%; 
      
        
      }
      .about-right h3{
         font-size: 14px;
         margin-left: 20px;
       
         margin-bottom: 17px;
      }
      .about-right-h{
        padding-top: 10px;
      }
    
      .app-image {
        width: 160px;
        margin-top: -10px;
        margin-left: 36px;
        height: 48px;
        margin-bottom: 0px;
      }
  
      .about-visitUs{
        font-size: 11px;
  
      }
      .about-address{
        font-size: 11px;
      }

  }
  @media (max-width: 765px) {
   
    .about-container{
      height: 340px;
  }
  .about-header {
      font-size: 26px;
      margin-left: 25px;
      margin-bottom: 0px;
    }

    .about-para{
      margin-left: 12px;
    }
  
    .about-left {
      flex: 0 0 26%;
      margin-left: 0px;
    }

    .about-left h3{
        font-size: 15px;
        margin-bottom: -6px;
    }

    .about-left p{
        font-size: 11px;
    }
  
    .about-middle {
      margin-left: -679px;
      margin-right: 0px;
    }
  
    .middle-image {
      width: 234px;
      height: 200px;
    }
  
    .about-right {  
      flex: 0 0 20%; 
    
      
    }
    .about-right h3{
       font-size: 13px;
       margin-left: 20px;
     
       margin-bottom: 17px;
    }
    .about-right-h{
      padding-top: 36px;
    }
  
    .app-image {
      width: 140px;
      margin-top: -10px;
      margin-left: 36px;
      height: 41px;
      margin-bottom: 0px;
    }

    .about-visit{
      padding-top: -20px;
    }

    .about-visitUs{
      font-size: 11px;
      padding-top: -40px;

    }
    .about-address{
      font-size: 11px;
    }
  }
  @media (max-width: 680px) {
   
    .about-container{
      height: 350px;
  }
  .about-header {
      font-size: 25px;
      margin-left: 0px;
      margin-bottom: 0px;
    }
   
    .about-left {
      flex: 0 0 26%;
      margin-left: 0px;
    }

    .about-left h3{
        font-size: 15px;
        margin-bottom: -6px;
    }

    .about-left p{
        font-size: 10.6px;
    }
  
    .about-middle {
      margin-left: -619px;
      margin-right: 0px;
    }
  
    .middle-image {
      width: 200px;
      height: 180px;
    }
  
    .about-right {  
      flex: 0 0 20%; 
    
      
    }
    .about-right h3{
       font-size: 12px;
       margin-left: 20px;
     
       margin-bottom: 17px;
    }
    .about-right-h{
      padding-top: 36px;
    }
  
    .app-image {
      width: 140px;
      margin-top: -10px;
      margin-left: 36px;
      height: 41px;
      margin-bottom: 0px;
    }

    .about-visit{
      padding-top: -20px;
    }

    .about-visitUs{
      font-size: 11px;
      padding-top: -40px;

    }
    .about-address{
      font-size: 11px;
    }
  }
  
  /* For screens 500px and below */
  @media (max-width: 620px) {
    .about-container{
        height: 260px;
    }
    .about-header {
        font-size: 20px;
        margin-left: 0px;
        margin-bottom: 0px;
      }
      .about-para{
        margin-top: -6px;
        font-size: 12px;
       }
    
      .about-left {
        flex: 0 0 22%;
        margin-left: 0px;
      }
  
      .about-left h3{
          font-size: 12px;
      }
  
      .about-left p{
          font-size: 7.2px;
      }
    
      .about-middle {
        margin-left: -580px;
        margin-right: 0px;
        margin-top: -30px;
      }
    
      .middle-image {
      
        width: 204px;
        margin-top: -60px;
        height: auto;
      }
    
      .about-right {  
        flex: 0 0 10%; 
      }
      .about-right h3{
         font-size: 9px;
         margin-left: 32px;
         margin-top: 20px;
         margin-bottom: 15px;
      }
      .about-right-h{
        padding-top: 60px;
      }
     
      .app-image {
        width: 100px;
        margin-left: 45px;
        height: 32px;
      }
  }
  
  @media (max-width: 550px) {
    .about-container{
        height: 260px;
    }
    .about-header {
        font-size: 20px;
        margin-left: 0px;
        margin-bottom: 0px;
      }
      .about-para{
        margin-top: -6px;
        font-size: 12px;
       }
    
      .about-left {
        flex: 0 0 22%;
        margin-left: 0px;
      }
  
      .about-left h3{
          font-size: 12px;
      }
  
      .about-left p{
          font-size: 7px;
      }
    
      .about-middle {
        margin-left: -535px;
        margin-right: 0px;
        margin-top: -25px;
      }
    
      .middle-image {
      
        width: 158px;
        
        height: 155px;
      }
    
      .about-right {  
        flex: 0 0 10%; 
      }
      .about-right h3{
         font-size: 8.6px;
         margin-left: 32px;
         margin-top: 10px;
         margin-bottom: 19px;
      }
      .about-right-h{
        padding-top: 80px;
      }
      .about-visitUs{
        font-size: 8.6px;
      }
      .about-address{
        font-size: 7.6px;
       margin-left: 20px;
      }
      .app-image {
        width: 90px;
        margin-left: 45px;
        height: 28px;
      }
  }
  
  @media (max-width: 520px) {
    /* Container for the entire About Section */
    .about-container {
      height: 620px; /* Set the height */
      padding: 20px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center; /* Centering the content */
    }
  
    /* Center the main title */
    .about-header {
      font-size: 24px;
      margin-bottom: 10px;
      text-align: center; /* Center the title */
    }
  
    /* Center the UdyogTech paragraph */
    .about-para {
      font-size: 16px;
      text-align: center; /* Center the text */
      margin-bottom: 20px;
    }
  
    /* Flex direction column for the whole content */
    .about-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    /* Left Section - Mission and Vision */
    .about-left {
      width: 100%;
      margin-bottom: 20px; /* Space between sections */
    }
  
    .about-left h3 {
      font-size: 18px;
      margin-bottom: -6px;
      text-align: center; /* Center the heading */
    }
  
    .about-left p {
      font-size: 14px;
      line-height: 1.2;
      text-align: center; /* Center the paragraph text */
    }
  
    /* Middle Section - Image */
    .about-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  

  
    .middle-image {
      width: 200px;
      margin-top: -638px;
      height: 150px; /* Adjusted height */
      object-fit: cover; /* Ensure the image fits properly */
      margin-left: 540px;
    }
    .our-vision{
      margin-top: 200px;
    }

  .about-right h3{
    font-size: 18px;
    margin-top: 48px;
    margin-left: 100px;
    margin-bottom: 8px;
  }

    .app-image {
      width: 160px;
      height: 42px; 
      
      margin: 0 auto;
      margin-left: 126px;
    }
  
    /* Visit us section */
    .about-visit {
      margin-top: 20px;
    }
  
    .about-visitUs {
      font-size: 16px;
      display: none;
      margin-bottom: 10px;
      text-align: center; /* Center the text */
    }
  
    .about-address {
      font-size: 14px;
      line-height: 1.5;
      display: none;
      text-align: center; /* Center the address */
    }
  }

 
  @media (max-width: 415px) {
    /* Container for the entire About Section */
    .about-container {
      height: 580px; /* Set the height */
      padding: 20px;

      text-align: center; /* Centering the content */
    }
  
    /* Center the main title */
    .about-header {
      font-size: 22px;
      margin-bottom: 10px;
      text-align: center; /* Center the title */
    }
  
    /* Center the UdyogTech paragraph */
    .about-para {
      font-size: 14px;
      margin-left: 0px;
      text-align: center; /* Center the text */
      margin-bottom: 20px;
    }
  
    /* Flex direction column for the whole content */
    .about-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    /* Left Section - Mission and Vision */
    .about-left {
      width: 100%;
      margin-bottom: 20px; /* Space between sections */
    }
  
    .about-left h3 {
      font-size: 16px;
      margin-bottom: -6px;
      text-align: center; /* Center the heading */
    }
  
    .about-left p {
      font-size: 12px;
    
      text-align: center; /* Center the paragraph text */
    }
  
    /* Middle Section - Image */
    .about-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  

  
    .middle-image {
      width: 200px;
      margin-top: -591px;
      height: 150px; /* Adjusted height */
      object-fit: cover; /* Ensure the image fits properly */
      margin-left: 540px;
    }
    .our-vision{
      margin-top: 200px;
    }

  .about-right h3{
    font-size: 16px;
    margin-top: 56px;
    margin-left: 80px;
    margin-bottom: 8px;
  }

    .app-image {
      width: 174px;
      height: 42px; 
      
      margin: 0 auto;
      margin-left: 98px;
    }
  
    /* Visit us section */
    .about-visit {
      margin-top: 20px;
    }
  
    .about-visitUs {
      font-size: 16px;
      display: none;
      margin-bottom: 10px;
      text-align: center; /* Center the text */
    }
  
    .about-address {
      font-size: 14px;
      line-height: 1.5;
      display: none;
      text-align: center; /* Center the address */
    }
  }

  
  @media (max-width: 365px) {
    /* Container for the entire About Section */
    .about-container {
      height: 600px; /* Set the height */
      padding: 20px;

      text-align: center; /* Centering the content */
    }
  
    /* Center the main title */
    .about-header {
      font-size: 22px;
      margin-bottom: 4px;
      text-align: center; /* Center the title */
    }
  
    /* Center the UdyogTech paragraph */
    .about-para {
      font-size: 12px;
      text-align: center; /* Center the text */
      margin-bottom: 20px;
    }
  
    /* Flex direction column for the whole content */
    .about-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    /* Left Section - Mission and Vision */
    .about-left {
      width: 100%;
      margin-bottom: 20px; /* Space between sections */
    }
  
    .about-left h3 {
      font-size: 16px;
      margin-bottom: -6px;
      text-align: center; /* Center the heading */
    }
  
    .about-left p {
      font-size: 12px;
    
      text-align: center; /* Center the paragraph text */
    }
  
    /* Middle Section - Image */
    .about-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  

  
    .middle-image {
      width: 200px;
      margin-top: -628px;
      height: 150px; /* Adjusted height */
      object-fit: cover; /* Ensure the image fits properly */
      margin-left: 540px;
    }
    .our-vision{
      margin-top: 200px;
    }

  .about-right h3{
    font-size: 16px;
    margin-top: 49px;
    margin-left: 80px;
    margin-bottom: 8px;
  }

    .app-image {
      width: 180px;
      height: 48px; 
      
      margin: 0 auto;
      margin-left: 94px;
    }
  
    /* Visit us section */
    .about-visit {
      margin-top: 20px;
    }
  
    .about-visitUs {
      font-size: 16px;
      display: none;
      margin-bottom: 10px;
      text-align: center; /* Center the text */
    }
  
    .about-address {
      font-size: 14px;
      line-height: 1.5;
      display: none;
      text-align: center; /* Center the address */
    }
  }