.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 141px;
    background-color: #35155D;
    color: #ECBC55;
}

.logo-section {
    display: flex;
    align-items: center;
    margin-left: 22px;
    margin-top: 21px;
    margin-bottom: 21px;
}

.logo {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-right: 13px;
}

.company-text h1 {
    margin-top: 28px;
    margin-bottom: 0px;
    color: #ECBC55;
    font-family: "Josefin Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.company-text p {
    margin-top: -3px;
    color: #FFF4CC;
    font-family: "Public Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.contact-section {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.contact-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 259px;
    height: 46px;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 46px;
    border-radius: 10px;
    background: #ECBC55;
    margin-right: 30px;
}

.contact-item {
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    color: #432C51;
}

.contact-item2 {
    margin-left: -38px;
}

.contact-item .material-icons {
    margin-right: 18px;
    text-decoration: none;
    font-size: 20px;
    color: #432C51;
}

.contact-item2 .material-icons {
    margin-right: 4px;
}

@media (max-width: 1100px) {
    .logo {
        width: 90px;
        height: 90px;
    }

    .company-text h1 {
        font-size: 35px;
        margin-top: 20px;
    }

    .company-text p {
        font-size: 22px;
    }

    .contact-box {
        width: 210px;
        padding-left: 25px;
    }

    .contact-item {
        font-size: 14px;
    }

    .contact-item2 {
        margin-left: -25px;
    }

    .contact-item .material-icons {
        font-size: 18px;
    }
}

@media (max-width: 1024px) {
    .logo {
        width: 80px;
        height: 80px;
    }

    .company-text h1 {
        font-size: 30px;
        margin-top: 18px;
    }

    .company-text p {
        font-size: 20px;
    }

    .contact-box {
        width: 190px;
        padding-left: 20px;
        margin-right: 12px;
    }

    .contact-item {
        font-size: 13px;
    }

    .contact-item2 {
        margin-left: -19px;
    }

    .contact-item .material-icons {
        font-size: 16px;
    }
}

@media (max-width: 850px) {
    .logo {
        width: 70px;
        height: 70px;
    }

    .company-text h1 {
        font-size: 28px;
        margin-top: 15px;
    }

    .company-text p {
        font-size: 18px;
    }

    .contact-box {
        width: 150px;
        padding-left: 15px;
    }

    .contact-item {
        font-size: 10px;
    }

    .contact-item2 {
        margin-left: -10px;
    }

    .contact-item .material-icons {
        font-size: 14px;
    }
}

@media (max-width: 690px) {
    .header-container {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .logo-section {
        margin: 0;
    }

    .logo {
        width: 60px;
        height: 60px;
        margin-left: 10px;
    }

    .company-text h1 {
        font-size: 24px;
        margin-top: 5px;
    }

    .company-text p {
        font-size: 16px;
    }

    .contact-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        margin-right: 10px;
    }

    .contact-box {
        width: 158px;
        height: 30px;
        border-radius: 10px;
        text-align: center;
    }

    .contact-item {
        font-size: 10px;
        padding-left: 20px;
        text-align: center;
    }

    .contact-item2 {
        padding-left: 0px;
    }

    .contact-item .material-icons {
        font-size: 14px;
        margin-right: 5px;
    }
}

@media (max-width: 520px) {
    .header-container {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        height: 92px;
        padding: 0 5px;
    }

    .logo-section {
        margin: 0;
    }

    .logo {
    
        width: 40px;
        height: 40px;
        margin-left: 3px;
    }

    .company-text h1 {
        font-size: 18px;
        
    }

    .company-text p {
        margin-top: 2px;
        font-size: 13px;
    }

    .contact-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;

        margin-right: 5px;
    }

    .contact-box {
        width: 130px;
        height: 25px;
        border-radius: 10px;
 
        text-align: center;
        padding: 5px;
    }

    .contact-item {
        font-size: 8px;
        padding-left: 25px;
        text-align: center;
    }

    .contact-item2 {
        padding-left: 10px;
    }

    .contact-item .material-icons {
        font-size: 12px;
        margin-right: 5px;
    }
}

@media (max-width: 400px) {
    .company-text p {
        font-size: 12px;
        width: 100px;
    }
}
