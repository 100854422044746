.benefits-section {
  background-color: #fff;
 overflow: hidden;
  text-align: center;
}

.benefits-heading {
  color: #432C51;
  font-family: Inter, sans-serif;
  margin-top: 23px;
  font-size: 40px; 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.benefits-category {
  margin-bottom: 60px;
}

.category-heading {
  color: #000;
  text-align: center;
  font-feature-settings: 'dlig' on;
  font-family: "Public Sans", sans-serif;
  font-size: 30px; /* Default */
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 104px;
}

.card-container {
  display: flex;
  justify-content: center;
  
  gap: 50px; 
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  position: relative;
  width: 260px; 
  height: 140px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  padding: 30px 50px; 
  border-radius: 30px;
  background: #35155D;
  
  margin-right: 30px;
}

.cardB {
  background: #ECBC55;
}

.imgBox {
  position: relative;
  width: 100%;
  transform: translateY(-100px); 
  z-index: 99;
}

.imgB {
  width: 100%;
  height: 120px; 
  border-radius: 30px;
  border: 5px solid #FFF;
  object-fit: cover;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
}

.sp1 {
  object-position: 0px -50px;
}

.card p {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'dlig' on;
  margin-top: -88px; 
  font-family: "Public Sans", sans-serif;
  font-size: 19px; 
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.cardB p {
  color: #432C51;
}

@media (max-width: 1150px) {
  .benefits-heading {
    font-size: 36px;
  }

  .category-heading {
    font-size: 28px;
    margin-bottom: 90px;
  }

  .card {
    width: 200px;
    height: 130px;
    padding: 25px 40px;
  }
  
  
.sp1 {
  object-position: 0px -14px;
}
  .card p {
    font-size: 18px;
  }
}

@media (max-width: 1050px) {
  .benefits-heading {
    font-size: 34px;
  }

  .category-heading {
    font-size: 26px;
    margin-bottom: 100px;
  }


  .card {
    width: 180px;
    height: 150px;
    padding: 20px 35px;
  }

  .card-container{
    gap: 45px;
  }
  
  .imgBox{
    transform: translateY(-80px); 
  }

  .imgB{
      height: 100px;
  }

  .card p {
    font-size: 17px;
    margin-top: -67px;
  }
}

@media (max-width: 980px) {
  .benefits-heading {
    font-size: 32px;
  }

  .category-heading {
    font-size: 24px;
    margin-bottom: 70px;
  }

  .card {
    width: 150px;
    height: 118px;
    padding: 15px 30px;
  }
  .card-container{
    gap: 40px;
  }

  .card p {
    font-size: 14px;
    line-height: 1.4;
    margin-top: -50px;
  }
 
.sp1 {
  object-position: 0px -10px;
}

  .imgBox {
    transform: translateY(-60px);
  }

  .imgB {
    height: 90px;
  }
}


@media (max-width: 840px) {
  .benefits-heading {
    font-size: 30px;
  }

  .category-heading {
    font-size: 22px;
    margin-bottom: 60px;
  }

  .card {
    width: 120px;
    height: 110px;
    padding: 15px 25px;
  }
  .card-container{
    gap: 35px;
  }

  .card p {
    font-size: 12px;
    margin-top: -50px;
  }
  .sp1 {
    object-position: 0px 0px;
  }

  .imgBox {
    transform: translateY(-60px);
  }

  .imgB {
    height: 80px;
  }
}

@media (max-width: 720px) {
  .benefits-heading {
    font-size: 26px;
  }

  .category-heading {
    font-size: 18px;
    margin-bottom: 60px;
  }

  .card {
    width: 100px;
    height: 100px;
    padding: 15px 25px;
  }

  .card p {
    font-size: 10px;
    margin-top: -57px;
  }
  .card-container{
    gap: 30px;
  }
  .sp1 {
    object-position: 0px 0px;
  }

  .imgBox {
    transform: translateY(-65px);
  }

  .imgB {
    height: 80px;
  }
}


@media (max-width: 620px) {
  .benefits-heading {
    font-size: 20px;
  }

  .category-heading {
    font-size: 15px;
    margin-bottom: 60px;
  }

  .card-container{
    gap: -0px;
  }

  .card {
    width: 90px;
    height: 100px;
    border-radius: 15px;
    padding: 10px 15px;
  }

  .card p {
    font-size: 10px;
  }

  .imgBox {
    transform: translateY(-60px);
  }

  .imgB {
    height: 84px;
    width: 84px;
  }
}

@media (max-width: 480px) {
  .benefits-section {
    padding-bottom: 20px;
  }

  .benefits-heading{
    font-size: 28px;
  }

  .mobile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .mobile-card {
    width: 90%;
    margin: 10px 0;
    position: relative; /* Ensures buttons are aligned relative to the card */
  }

  .category-heading{
    font-size: 20px;
    margin-bottom: 146px;
    margin-top: 0px;
  }

  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Required for absolutely positioned buttons */
  }

  .slider-btn {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Correct vertical alignment */
    width: 32px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .slider-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .slider-btn.prev-btn {
    top: 145px;
    left: 10px; /* Align to the left inside the card */
  }

  .slider-btn.next-btn {
    top: 145px;
    right: 10px; /* Align to the right inside the card */
  }

  .card,
  .cardB {
    width: 100%; /* Full width for better alignment */
    height: 220px; /* Increased height for better visuals */
    margin: 0 auto; /* Center alignment */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    background: #35155D;
    position: relative; /* To contain the slider buttons */
  }

  .cardB {
    background: #ECBC55;
  }

  .imgBox {
    width: 94%;
    height: 120px;
    transform: translateY(-128px); 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .imgBox1{
    transform: translateY(-133px); 
    width: 94%;
    height: 120px;
   
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .imgB {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 0px;
  }

  .card p { 
    font-size: 15px;
    margin: 0 15px;
    margin-left: 27px;
    margin-right: 27px;
    margin-top: -56px;
    text-align: center;
  }

  .cardB p{
    font-size: 15px;
    margin: 0 15px;
    padding: 0px;
    height: 40px;
    margin-left: 27px;
    margin-right: 27px;
    margin-top: -58px;
    text-align: center;
  }

}

@media (max-width: 400px) {
  .benefits-section {
    padding-bottom: 20px;
  }

  .benefits-heading{
    font-size: 28px;
  }

  .mobile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .mobile-card {
    width: 90%;
    margin: 10px 0;
    position: relative; /* Ensures buttons are aligned relative to the card */
  }

  .category-heading{
    font-size: 20px;
    margin-bottom: 120px;
    margin-top: 0px;
  }

  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Required for absolutely positioned buttons */
  }

  .slider-btn {
    position: absolute;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Correct vertical alignment */
    width: 32px;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .slider-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .slider-btn.prev-btn {
    top: 145px;
    left: 10px; /* Align to the left inside the card */
  }

  .slider-btn.next-btn {
    top: 145px;
    right: 10px; /* Align to the right inside the card */
  }

  .card,
  .cardB {
    width: 100%; /* Full width for better alignment */
    height: 220px; /* Increased height for better visuals */
    margin: 0 auto; /* Center alignment */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    background: #35155D;
    position: relative; /* To contain the slider buttons */
  }

  .cardB {
    background: #ECBC55;
  }

  .imgBox {
    width: 94%;
    height: 120px;
    transform: translateY(-128px); 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .imgBox1{
    transform: translateY(-133px); 
    width: 94%;
    height: 120px;
   
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .imgB {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 0px;
  }

  .card p { 
    font-size: 15px;
    margin: 0 15px;
    margin-left: 27px;
    margin-right: 27px;
    margin-top: -80px;
    text-align: center;
  }

  .cardB p{
    font-size: 15px;
    margin: 0 15px;
    padding: 0px;
    height: 40px;
    margin-left: 27px;
    margin-right: 27px;
    margin-top: -82px;
    text-align: center;
  }

}